import React from "react";
import {
  HomeContainer,
  HomeBackground,
  HomeTitle,
  SectionsContainer,
  LinkR,
  LinkContainer,
  ArrowBeer,
  TitleDecoration,
} from "./HomeElements";

const Home = ({ menuSections }) => {

  return (
    <>
      <HomeContainer>
        <HomeBackground />
        <HomeTitle onClick={() => console.log(document.cookie)}>
          Menu digital
        </HomeTitle>
        <SectionsContainer>
          {menuSections.map((item, index) => (
            <LinkContainer key={index} special={item.special}>
              <LinkR to={`${item.route}`}>
                <h2>{item.name}</h2>
                {item.burger && <TitleDecoration />}
                {item.beer && <ArrowBeer />}
              </LinkR>
            </LinkContainer>
          ))}
        </SectionsContainer>
      </HomeContainer>
    </>
  );
};

export default Home;
