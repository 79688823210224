import "./App.css";
import Home from "./home/Home";
import Section from "./home/Section";
import { Routes, Route } from "react-router-dom";
import burgers from "./images/burgers.jpg";
import sin from "./images/sin.jpg";
import dulces from "./images/dulces.jpg";
import ensaladas from "./images/ensaladas.jpg";
import picar from "./images/picar.jpg";
import pizzas from "./images/pizzas.jpg";
import sambuches from "./images/sambuches.jpg";
import tragos from "./images/tragos.jpg";
import cervezas from "./images/cervezas.jpg";

function App() {
  const menuSections = [
    {
      name: "burgers",
      route: "burgers",
      special: true,
      burger: true,
    },
    {
      name: "para picar",
      route: "picar",
    },
    {
      name: "sambuches",
      route: "sambuches",
    },
    {
      name: "pizzas",
      route: "pizzas",
    },
    {
      name: "ensaladas",
      route: "ensaladas",
    },
    {
      name: "dulces",
      route: "dulces",
    },
    {
      name: "sin alcohol",
      route: "sin",
    },
    {
      name: "cervezas",
      route: "cervezas",
      special: true,
      beer: true,
    },
    {
      name: "tragos",
      route: "tragos",
    },
  ];

  return (
    <>
      <Routes>
        <Route path="/" element={<Home menuSections={menuSections} />} />
        <Route path="/burgers" element={<Section category={burgers} />} />
        <Route path="/picar" element={<Section category={picar} />} />
        <Route path="/sambuches" element={<Section category={sambuches} />} />
        <Route path="/pizzas" element={<Section category={pizzas} />} />
        <Route path="/ensaladas" element={<Section category={ensaladas} />} />
        <Route path="/dulces" element={<Section category={dulces} />} />
        <Route path="/sin" element={<Section category={sin} />} />
        <Route path="/cervezas" element={<Section category={cervezas} />} />
        <Route path="/tragos" element={<Section category={tragos} />} />
      </Routes>
    </>
  );
}

export default App;
